import React from "react"
import style from './Footer.module.scss'

const Footer = () => (
  <footer className={style.footer}>
    {/* <div className={style.inner}> */}

      © {new Date().getFullYear()}
      {` `}
      <a href="https://guerillacreative.tv/" target="_blank" rel="noopen noreferrer">Guerilla Creative</a> • 
      <a href="https://guerillacreative.tv/privacy" target="_blank" rel="noopen noreferrer">Privacy Policy</a>
    {/* </div> */}
  </footer>
)

export default Footer
