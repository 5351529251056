/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
  title: string
}

export default function SEO({ title }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteURL
          }
        }
      }
    `
  )

  const description = site.siteMetadata.description
  const pageType = 'Website'
  const siteURL = site.siteMetadata.siteURL
  const twitterHandle = site.siteMetadata.author

  // https://search.google.com/structured-data/testing-tool?utm_campaign=devsite&utm_medium=jsonld&utm_source=local-business
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": pageType,
      "@id": siteURL,
      "url": siteURL,
      name: title,
      headline: title,
      // image: {
      //   '@type': 'ImageObject',
      //   url: image,
      // },
      description,
      publisher: {
        '@type': 'Organization',
        name: 'Monash University',
        // logo: {
        //   '@type': 'ImageObject',
        //   url: seo_logo,
        // },
      },
      isPartOf: siteURL,
      mainEntityOfPage: {
        '@type': 'WebSite',
        '@id': siteURL,
      },
    }
  ]

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {/* {image && <meta name="image" content={`${siteURL}${image}`} />} */}

      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      <meta property="og:url" content={siteURL} />
      <meta property="og:type" content={pageType} />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}

      {/* {image && <meta property="og:image" content={`${siteURL}${image}`} />} */}

      {twitterHandle && <meta name="twitter:creator" content={twitterHandle} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />

      {description && <meta name="twitter:description" content={description} />}
      {/* {image && <meta name="twitter:image" content={`${siteURL}${image}`} />} */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}
